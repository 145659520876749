import SEO from "components/SEO";
import { BRASS_PRESS_KIT_URL } from "data/config";
import pagesMeta from "data/pagesMeta";
import React from "react";
import appData from "../../../app.json";
import { LayoutContext } from "../../context";
import { DEFAULT_VALUES } from "../../context/Layout";

import * as styles from "./press.module.scss";

const Press = () => {
  const { updateContext } = React.useContext(LayoutContext);
  React.useEffect(() => {
    updateContext({ isFooterButtonSticky: false });

    return () => {
      updateContext(DEFAULT_VALUES);
    };
  }, []);

  return (
    <>
      <SEO {...pagesMeta.press} />

      <section className={styles.Press}>
        <div className={styles.Press_container}>
          <h1 className={styles.Press_container_ttl}>Brass press resources</h1>
          <p>
            Brass provides top-end financial products for local businesses,
            helping with payment services and business growth. It serves
            thousands of businesses and operates from Lagos, Nigeria with
            international ambition.
          </p>
          <br />
          <p>
            It was founded by Sola Akindolu and Emmanuel Okeke in 2020 with a
            mission to making economic prosperity accessible to everyone. Brass
            is currently a seed company.
          </p>

          <div className={styles.Press_container_divider} />

          <p>
            Download our full media assets kit, including logo symbols, and a
            usage guide to help determine when and how to use our brand.
          </p>
          <a
            className={styles.Press_container_link}
            href={BRASS_PRESS_KIT_URL}
            target="_blank"
          >
            Download assets
          </a>
          <a
            className={styles.Press_container_link}
            href={`mailto:${appData.emails.press}`}
          >
            {appData.emails.press}
          </a>
        </div>
      </section>
    </>
  );
};

export default Press;
